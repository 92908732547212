<template>
  <div class="max-w-2xl mx-auto relative">

    <div class="max-w-2xl mx-auto p-2 relative" style="background: linear-gradient(135deg, #9c27b0, #673ab7); color: white; padding: 10px; text-align: center;border-radius: 0 0 20px 20px;">
      <!-- 顶部聊天和语言切换图标 -->
      <div class=" top-4 right-4 z-20 flex justify-between space-x-4" style="align-items: center;">
        <span>自动贩售机</span>
        <span>
          <!-- 多语言切换按钮 -->
        <button @click="toggleLanguage" class=" p-2 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30 40" class="icon">
            <circle cx="20" cy="20" r="16" fill="white" stroke="#8e24aa" stroke-width="2"/>
            <circle cx="20" cy="20" r="10" fill="none" stroke="#8e24aa" stroke-width="2"/>
            <line x1="10" y1="20" x2="30" y2="20" stroke="#8e24aa" stroke-width="2"/>
            <path d="M20 10a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" fill="none" stroke="#8e24aa" stroke-width="2"/>
          </svg>
        </button>
          <!-- 聊天按钮 -->
        <button @click="goToChat" class=" p-2 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30 40" class="icon">
            <circle cx="20" cy="20" r="16" fill="white" stroke="#8e24aa" stroke-width="2"/>
            <path d="M29 19.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L11 29l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" fill="none" stroke="#8e24aa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        </span>
      </div>

      <!-- 轮播图 -->
      <div class="mb-2">
        <Splide :options="{ type: 'loop', perPage: 1, autoplay: true, interval: 13000, gap: '1rem' }">
          <SplideSlide v-for="(slide, index) in slideslist()" :key="index">
            <img :src="slide.image" :alt="`Slide ${index + 1}`" class="w-full h-auto rounded-lg" />
          </SplideSlide>
        </Splide>
      </div>
    </div>

    <div class="max-w-2xl mx-auto p-2 relative">
      <!-- 商品列表 -->
      <div class="grid grid-cols-3 gap-4">
        <div class="relative bg-gray-100 rounded-lg text-center flex flex-col items-center h-full" v-for="(product, index) in productlist()" :key="index">
          <!-- 固定图片高度，使用 object-cover 确保图片不失真 -->
          <img :src="product.image" :alt="product.name" class="w-full h-48 rounded-lg cursor-pointer object-cover" @click="showProductDetails(product)" />

          <!-- 添加购物车按钮 -->
          <button class="absolute top-1 right-1 bg-white p-1 rounded-full shadow-lg hover:bg-gray-200" @click="addToCart(product)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2 9m10-9l2 9m-4-9v9" />
            </svg>
          </button>

          <!-- 商品名称 -->
          <h3 class="text-sm my-2 truncate-lines cursor-pointer" @click="showProductDetails(product)">{{ product.name }}</h3>

          <!-- 商品价格 -->
          <p class="index-goods-item-price text-gray-500">{{ product.price }}</p>
        </div>
      </div>


      <!-- 商品详情弹窗 -->
      <div v-if="showProductDetailModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
        <div class="bg-white p-3 rounded-lg shadow-lg w-4/5  max-w-4xl  overflow-auto">
          <div class="w-full h-72 mb-3">
            <img :src="selectedProduct.image" :alt="selectedProduct.name" class="w-full h-full rounded-lg object-cover" />
          </div>
          <h2 class="text-xl font-bold mb-2">{{ selectedProduct.name }}</h2>
          <p class="text-gray-500 mb-2">{{ selectedProduct.price }}</p>
          <p class="text-gray-700 mb-3">{{ selectedProduct.description }}</p>
          <div class="flex justify-between mt-2">
            <button style="border-radius: 20px;" class="bg-purple-600 text-white py-2 px-3 rounded hover:bg-blue-700" @click="sendMessage">{{ $t('pay') }}</button>
            <button style="border-radius: 20px;" class="bg-purple-600 text-white py-2 px-3 rounded hover:bg-blue-700" @click="closeProductDetailModal">{{ $t('close') }}</button>
          </div>
        </div>
      </div>

      <!-- 购物车弹窗 -->
      <div v-if="showCart" class="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 z-30 border-t border-gray-200">
        <div class="max-w-2xl mx-auto">
          <h2 class="text-xl mb-4">{{ $t('cart') }}</h2>
          <ul class="space-y-4">
            <li v-for="(item, index) in cart" :key="index" class="flex items-center justify-between">
              <img :src="item.image" :alt="item.name" class="w-12 h-12 object-cover rounded-lg" />
              <div class="flex items-center justify-center">
                <span class="truncate-lines-cart">{{ item.name }}</span>
                <span class="index-goods-item-price text-gray-200">{{ item.price }}</span>
                <button class="text-red-500 hover:text-red-700" @click="removeFromCart(index)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>
              </div>
            </li>
          </ul>
          <div class="flex justify-between mt-4">
            <button style="border-radius: 20px;" @click="goToChat" class="bg-purple-600 text-white py-2 px-4 rounded hover:bg-blue-700">{{ $t('pay') }}</button>
            <button style="border-radius: 20px;" @click="showCart = false" class="bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-400">{{ $t('close') }}</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { PRODUCTLIST_EN, PRODUCTLIST_ZH,SLIDES_ZH,SLIDES_EN } from '@/constants/index';
import { useI18n } from 'vue-i18n';
import { useWebSocketStore } from '../stores/websocket';

const { t,locale } = useI18n(); // 获取当前语言

const webSocketStore = useWebSocketStore();

const router = useRouter();
const goToChat = () => {
  if(cart.value){
    cart.value.forEach(product =>{
      const msg = {
      isCustomer: 0,
      userId: localStorage.getItem('browserFingerprint'),
      from: localStorage.getItem('browserFingerprint'),
      message: 'https://520love.shop' + product.image,
      msgType: 2,
      messageType: 'img',
      topicId: localStorage.getItem('browserFingerprint'),
      to: 1811849605035298818,
      platform: 'h5',
      sendReceive: 'send',
      physicalId: localStorage.getItem('browserFingerprint'),
    };
    webSocketStore.sendMessage(msg);
    });
    router.push('/chat');
  }
};

// 数据定义
const showCart = ref(false);
const cart = ref([]);

// 添加商品到购物车
const addToCart = (product) => {
  cart.value.push(product);
  showCart.value = true;
};

// 从购物车移除商品
const removeFromCart = (index) => {
  cart.value.splice(index, 1);
};

// 商品详情弹窗相关数据
const showProductDetailModal = ref(false);
const selectedProduct = ref({});

// 显示商品详情弹窗
const showProductDetails = (product) => {
  selectedProduct.value = product;
  showProductDetailModal.value = true;
};

// 关闭商品详情弹窗
const closeProductDetailModal = () => {
  showProductDetailModal.value = false;
};

// 语言切换操作
const toggleLanguage = () => {
  const newLocale = locale.value === 'zh' ? 'en' : 'zh';
  locale.value = newLocale; // 更新语言
};

// 获取商品列表
const productlist = () => {
  return locale.value === 'zh' ? PRODUCTLIST_ZH : PRODUCTLIST_EN;
};

const slideslist = () => {
  return locale.value === 'zh' ? SLIDES_ZH : SLIDES_EN;
};

const sendMessage = () =>{
  const msg = {
      isCustomer: 0,
      userId: localStorage.getItem('browserFingerprint'),
      from: localStorage.getItem('browserFingerprint'),
      message: 'https://520love.shop' + selectedProduct.value.image,
      msgType: 2,
      messageType: 'img',
      topicId: localStorage.getItem('browserFingerprint'),
      to: 1811849605035298818,
      platform: 'h5',
      sendReceive: 'send',
      physicalId: localStorage.getItem('browserFingerprint'),
    };
    webSocketStore.sendMessage(msg); // 发送消息
    router.push('/chat');
}
</script>

<style scoped>
@import "@splidejs/splide/dist/css/themes/splide-default.min.css";
/* 自定义样式 */
.truncate-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}

.truncate-lines-cart {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
  margin: 3px;
}

.index-goods-item-price {
  color: #d55e5c;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .5px;
  margin-top: 3px;
  font-family: dingding;
}
</style>


