export const PRODUCTLIST_EN = [
    {
      "image": "/images/en/1.jpg",
      "name": "[Sleeping Research Institute] Newbie-friendly small-sized sex vibrator Svakom Cici",
      "price": "$90"
    },
    {
      "image": "/images/en/2.jpg",
      "name": "Agobi 7 in 1 Premium Maca Root Maca Capsules with Ashwagandha 150 Capsules",
      "price": "$85"
    },
    {
      "image": "/images/en/3.jpg",
      "name": "BDSM Lingerie Crotchless Sex Tight Bodysuit Adult + Open",
      "price": "$15"
    },
    {
      "image": "/images/en/4.jpg",
      "name": "Intimate Earth edible imitation human secretion fruity private parts smooth lubricant oil",
      "price": "$38"
    },
    {
      "image": "/images/en/5.jpg",
      "name": "Japan imported Okamoto 001 happiness 0.01MM condom plus large size condom ultra-thin L size yellow label",
      "price": "$18"
    },
    {
      "image": "/images/en/6.jpg",
      "name": "Japan imported Okamoto Sagami Happiness 001 ultra-thin 0.01 condom 002mm ultra-thin condom",
      "price": "$12"
    },
    {
      "image": "/images/en/7.jpg",
      "name": "Men's night toys, men's decompression artifact, men's health massager, men's decompression artifact for boys",
      "price": "$66"
    },
    {
      "image": "/images/en/8.jpg",
      "name": "Rose Red Lips Vibrator Clitoris Stimulator Vagina Licking",
      "price": "$78"
    },
    {
      "image": "/images/en/9.jpg",
      "name": "satisfyer pro GENERATION  app vibratorde clitoris sex toys",
      "price": "$88"
    },
    {
      "image": "/images/en/10.jpg",
      "name": "Use the beast to insert the egg ap style p static different remote jump monster wear remote external control into the strong earthquake small sound sex out wear NEC female product",
      "price": "$70"
    },
    {
      "image": "/images/en/11.jpg",
      "name": "Urinalysis Urine Test Strip 10 Parameters at Home Urinary Tract Infection Test Kit ",
      "price": "$12"
    }
  ];
export const PRODUCTLIST_ZH = [
    {
        "image": "/images/zh/1.jpg",
        "name": "【臻萃玫瑰】杰士邦玫瑰人体润滑液剂房事夫妻用品男女性私处爽滑",
        "price": "￥98"
    },
    {
        "image": "/images/zh/2.jpg",
        "name": "2024新款情趣内衣性感肚兜古风汉服激情制服睡衣床上免脱国潮衣服",
        "price": "￥88"
    },
    {
        "image": "/images/zh/3.jpg",
        "name": "Cumlaude人体润滑油高潮女性专用润滑剂女私密可食用舔食液激情液",
        "price": "￥138"
    },
    {
        "image": "/images/zh/4.jpg",
        "name": "AV震动棒按摩阴蒂调情趣自慰器女性用品高潮专用神器夫妻共用吹潮",
        "price": "￥268"
    },
    {
        "image": "/images/zh/5.jpg",
        "name": "kisstoy秒潮polly三代女性吮吸自慰神器成人玩具情趣女用品震动棒",
        "price": "￥198"
    },
    {
        "image": "/images/zh/6.jpg",
        "name": "sm情趣用品金属合金手铐捆绑束缚调教狗奴男女玩具脚铐刑具性用品",
        "price": "￥88"
    },
    {
        "image": "/images/zh/7.jpg",
        "name": "sm蝴蝶结手铐脚铐牵引项圈调情捆绑束缚刑具情趣用品调教另类玩具",
        "price": "￥98"
    },
    {
        "image": "/images/zh/8.jpg",
        "name": "冈本玻尿酸003丨超薄避孕套安全套套0.01情趣男女t",
        "price": "￥78"
    },
    {
        "image": "/images/zh/9.jpg",
        "name": "口交水液冰火两重天成人性用品情趣用具房趣合欢夫妻调情神器口娇",
        "price": "￥48"
    },
    {
        "image": "/images/zh/10.jpg",
        "name": "大人糖逗豆鸟PRO2代跳蛋情趣用品自慰器成人玩具女性高潮吮吸震动",
        "price": "￥238"
    },
    {
        "image": "/images/zh/11.jpg",
        "name": "性感睡衣蕾丝紧身挂脖极短百褶裙热诱弹纯欲情调cos小野猫床上q趣",
        "price": "￥108"
    },
    {
        "image": "/images/zh/12.jpg",
        "name": "情趣内衣2024新款性感免脱小胸显大聚拢制服诱惑火辣激情挑逗套装",
        "price": "￥168"
    },
    {
        "image": "/images/zh/13.jpg",
        "name": "情调黑丝丝袜女夏薄款防勾丝不掉档黑色性感光腿神器禁欲系连裤袜",
        "price": "￥88"
    },
    {
        "image": "/images/zh/14.jpg",
        "name": "日本代购情趣内衣2024新款性感睡衣免脱小胸制服诱惑装激情套装女",
        "price": "￥118"
    },
    {
        "image": "/images/zh/15.jpg",
        "name": "杰士邦全自动飞机杯男用伸缩成人情趣用品电动自慰器男性训练玩具",
        "price": "￥388"
    },
    {
        "image": "/images/zh/16.jpg",
        "name": "杰士邦避孕套超薄裸入安全001情趣刺激男用延时夜草莓t",
        "price": "￥68"
    },
    {
        "image": "/images/zh/17.jpg",
        "name": "杰士邦003玫瑰避孕套001超薄裸入持久装安全国家免费",
        "price": "￥78"
    },
    {
        "image": "/images/zh/18.jpg",
        "name": "漫拨女性成人玩具震动棒吮吸自慰器女用品g点刺激高潮神器",
        "price": "￥288"
    },
    {
        "image": "/images/zh/19.jpg",
        "name": "纳维密情趣内衣诱惑纯欲婚纱2024新款透视免脱制服性感睡衣激情女",
        "price": "￥88"
    },
    {
        "image": "/images/zh/20.jpg",
        "name": "美国进口润滑精油剂液女性高潮增强欲望私处阴道情趣用品私密快感",
        "price": "￥188"
    },
    {
        "image": "/images/zh/21.jpg",
        "name": "跳蛋女性舌舔阴蒂自慰器吮吸小玩具情趣成人高潮女用品不入体怪兽",
        "price": "￥218"
    },
    {
        "image": "/images/zh/22.jpg",
        "name": "迈为艾滋梅毒乙肝丙肝四项联合检测试剂板型单人份1人份每盒",
        "price": "￥48"
    },
    {
        "image": "/images/zh/23.jpg",
        "name": "避孕套撩心狼牙带刺超大颗粒男用大号安全持久情趣超薄",
        "price": "￥68"
    }
];
export const SLIDES_ZH = [
  { image: '/images/zh/slide1.jpg' },
  { image: '/images/zh/slide2.jpg' },
];
export const SLIDES_EN = [
    { image: '/images/en/slide1.jpg' },
    { image: '/images/en/slide2.jpg' },
];
