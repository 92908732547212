import { createApp } from 'vue'
import App from './App.vue'
import './main.css'
import { createI18n } from 'vue-i18n';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { simpleFingerprintTo18Digits } from './funs/common';
import router from './router'; // 引入 router
import { createPinia } from 'pinia';
import { useWebSocketStore } from './stores/websocket';
import en from './locales/en.json';
import zh from './locales/zh.json';


const i18n = createI18n({
  locale: 'en', // 默认语言
  messages: {
    en,
    zh,
  },
});

const app = createApp(App);
const pinia = createPinia();


const checkAndClearLocalStorage = (expectedVision) => {
  const currentVision = localStorage.getItem('vision');  // 获取 localStorage 中的 vision 值
  
  if (currentVision != expectedVision) {
    localStorage.clear();  // 如果 vision 不等于预期值，清空 localStorage
    localStorage.setItem('vision', expectedVision);
    console.log('localStorage has been cleared.');
  } else {
    console.log('vision is correct, localStorage remains unchanged.');
  }
};

const checkAndStoreFingerprint = () => {
  return new Promise((resolve, reject) => {
    const existingFingerprint = localStorage.getItem('browserFingerprint');
    
    if (!existingFingerprint) {
      // 如果没有指纹，生成一个新的指纹
      FingerprintJS.load()
        .then(fp => fp.get())
        .then(result => {
          const visitorId = result.visitorId;
          localStorage.setItem('browserFingerprint', simpleFingerprintTo18Digits(visitorId));
          resolve(visitorId);  // 生成指纹成功后 resolve
        })
        .catch(error => {
          console.error('Failed to generate fingerprint:', error);
          reject(error);  // 生成指纹失败后 reject
        });
    } else {
      console.log('Existing Browser Fingerprint:', existingFingerprint);
      resolve(existingFingerprint);  // 如果指纹已存在，直接 resolve
    }
  });
};

// 应用启动时，先清理 localStorage 再检查生成指纹
checkAndClearLocalStorage(1);

// 等待 checkAndStoreFingerprint 执行完毕后，再初始化 WebSocket 连接
checkAndStoreFingerprint()
  .then(fingerprint => {
    const webSocketStore = useWebSocketStore();
    webSocketStore.initWebSocket('wss://admin.520love.shop/admin/cs/join/' + fingerprint);
  })
  .catch(error => {
    console.error('Error initializing WebSocket:', error);
  });

app.use(pinia);
app.use(router).use(i18n).mount('#app');