// stores/websocket.js
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useWebSocketStore = defineStore('websocket', () => {
  const socket = ref(null);
  const messages = ref([]);

  const initWebSocket = (url) => {
    socket.value = new WebSocket(url);

    socket.value.onopen = () => {
      console.log('WebSocket 连接已建立');
    };

    socket.value.onmessage = (event) => {
      const receivedMessage = event.data;
      messages.value.push(JSON.parse(receivedMessage));
    };

    socket.value.onclose = () => {
      console.log('WebSocket 连接已关闭，尝试重新连接...');
      setTimeout(() => initWebSocket(url), 3000); // 自动重连
    };

    socket.value.onerror = (error) => {
      console.error('WebSocket 连接出错:', error);
      socket.value.close();
    };
  };

  const sendMessage = (msg) => {
    if (socket.value && socket.value.readyState === WebSocket.OPEN) {
      socket.value.send(JSON.stringify(msg));
    }
  };

  return {
    socket,
    messages,
    initWebSocket,
    sendMessage,
  };
});
