import axios from 'axios';

export const simpleFingerprintTo18Digits = (fingerprint) => {
  let num = 0;
  
  // 遍历指纹字符串的每个字符，并根据字符编码计算加权和
  num = fingerprint.split('').reduce((acc, char, index) => {
      return acc + (char.charCodeAt(0) * (index + 1));
  }, 0);

  // 将结果转为字符串
  let result = Math.abs(num).toString();

  // 生成一个额外的随机数以增强唯一性
  const randomPart = Math.floor(Math.random() * 1000000000).toString();

  // 将结果拼接并取前18位
  result = (result + randomPart).slice(0, 18);

  // 如果生成的数字少于 18 位，用 '0' 填充
  return result.padEnd(18, '0');
};

export const uploadFile = (file) => {
  const formData = new FormData();
  formData.append('file', file); // 将文件添加到 FormData 中

  return axios.post('https://520love.shop/admin/system/upload/one', formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // 必须设置这个头部
    },
  })
  .then(response => {
    return response.data;  // 返回响应的数据
  })
  .catch(error => {
    console.error('File upload failed:', error);
    throw error;  // 抛出错误，方便页面中处理
  });
};