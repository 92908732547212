import axios from 'axios';

export const getMessages = async (id) => {
  try {
    const response = await axios.get('https://520love.shop/admin/cs/chat/getUserMessages?physicalId=' + id);
    return response.data; // 返回响应的数据
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error; // 重新抛出错误以便在 getmsglists 中捕获
  }
};