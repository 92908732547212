<template>
  <div class="flex flex-col h-[100vh] max-w-[720px] mx-auto p-4 bg-gray-200 shadow-lg">
    <!-- 返回首页的图标 -->
    <button @click="goHome" class="back-button bg-none border-none cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="w-4 h-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 12h18M12 3l-9 9 9 9"
        />
      </svg>
    </button>

    <div class="messages flex-1 p-2 bg-gray-200 border border-gray-300 rounded-lg overflow-y-auto">
      <!-- 聊天消息列表 -->
      <div class="flex flex-col">
      <div class="message my-2 flex" v-for="(msg, index) in messages" :key="index" :class="msg.sendReceive === 'send' ? 'justify-end' : 'justify-start'">
        <span v-if="msg.messageType === 'img'">
          <img 
            :src="msg.message"  
            alt="Image message"
            class="max-w-xs rounded-lg" 
          />
        </span>
        <span v-else
          :class="msg.sendReceive === 'send' ? 'bg-green-200 inline-block p-2 rounded-l-lg rounded-br-lg' : 'bg-pink-200 inline-block p-2 rounded-r-lg rounded-bl-lg'">
          {{ msg.message }}
        </span>
      </div>
    </div>
    </div>

    <!-- 输入区域 -->
    <div class="input-wrapper flex items-center gap-2 p-2 border border-gray-300 rounded-lg bg-gray-200">
      <input
        type="file"
        @change="handleFileUpload"
        class="file-upload hidden"
        ref="fileInput"
        accept="image/*"
      />
      <!-- SVG 上传按钮 -->
      <button @click="triggerUpload" class="upload-button bg-none border-none cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 16v4a2 2 0 002 2h14a2 2 0 002-2v-4m-8-2v-4m0 0L9 7m4-2l4 4M5 16h14a2 2 0 002-2V9a2 2 0 00-2-2H5a2 2 0 00-2 2v5a2 2 0 002 2z"
          />
        </svg>
      </button>

      <input
        type="text"
        v-model="message"
        placeholder=""
        @keyup.enter="sendMessage"
        class="message-input flex-1 p-2 border border-gray-300 rounded"
      />
      <button @click="sendMessage" class="send-button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
        {{ $t('send') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { getMessages } from "../funs/chat"
import { uploadFile } from "../funs/common"
import { useWebSocketStore } from '../stores/websocket';


const router = useRouter();
const messages = ref([]);
const message = ref('');
const webSocketStore = useWebSocketStore();
const fingerprint = ref(localStorage.getItem('browserFingerprint'));
let socket = null;

const goHome = () => {
  router.push('/');
};


// 发送消息
const sendMessage = () => {
  if (message.value.trim()) {
    const msg = {
      isCustomer: 0,
      userId: fingerprint.value,
      from: fingerprint.value,
      message: message.value,
      msgType: 1, // 1: 文本消息 text  2: 图片 img
      messageType: 'txet',
      topicId:fingerprint.value,
      to:1811849605035298818,
      platform: 'h5',
      sendReceive: 'send',
      physicalId: fingerprint.value,
    };
    webSocketStore.sendMessage(msg); // 通过 WebSocket 发送消息
    messages.value.push(msg); // 本地添加消息
    message.value = ''; // 清空输入框
  }
};

// 触发文件上传
const triggerUpload = () => {
  fileInput.value.click();
};

// 处理文件上传
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    // 处理文件上传逻辑
    uploadFile(file)
      .then(response => {

        const msg = {
          isCustomer: 0,
          userId: fingerprint.value,
          from: fingerprint.value,
          message: response.data,
          msgType: 2, // 1: 文本消息 text  2: 图片 img
          messageType: 'img',
          topicId:fingerprint.value,
          to:1811849605035298818,
          platform: 'h5',
          sendReceive: 'send',
          physicalId: fingerprint.value,
        };

        webSocketStore.sendMessage(msg); // 通过 WebSocket 发送消息
        messages.value.push(msg); // 本地添加消息
        
      })
      .catch(error => {
        console.error('Error uploading file:', error);  // 处理上传错误
      });
  }
};

const fileInput = ref(null);

const getmsglists = async() =>{
  console.log(fingerprint.value)
  const res = await getMessages(fingerprint.value);
  messages.value = res.data
}

onMounted( () => {
  getmsglists();
});

onBeforeUnmount(() => {
 
});
</script>






