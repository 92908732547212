import { createRouter, createWebHistory } from 'vue-router';
import ChatPage from '../components/ChatPage.vue'; // 引入聊天页面组件
import HomePage from '../components/HomePage.vue'; // 引入聊天页面组件

const routes = [
  // 其他路由可以在这里添加
  { path: '/', component: HomePage }, // 聊天页面的路由
  { path: '/chat', component: ChatPage }, // 聊天页面的路由
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
